import { Button } from 'antd';
import { t } from 'i18next';
import { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useMutation } from 'react-query';
import { deleteAccount, restoreAccount } from 'services/api';
import { logoutFromAllAccounts } from 'services/authorization';
import { updateUserPreferences, updateUserName } from 'services/api';

import { useApplicationContextState } from 'components/ApplicationContext/hooks';

import { AppNotification, NotificationTypes } from 'components/Notification';

export function useUpdateUserNameMutation() {
    const { getOrganization, getUser } = useApplicationContextState();
    const { id: tid } = getOrganization();
    const { updateUserName: updateUserNameInAppContext } = getUser();

    const mutation = useMutation(
        ({ userId, name }) => {
            return updateUserName({ organizationId: tid, userId, name });
        },
        {
            onSuccess: (data) => {
                updateUserNameInAppContext(data.result.name);
            },
        },
    );

    return mutation;
}

export function useUpdateUserPreferencesMutation() {
    const { getOrganization, getUser } = useApplicationContextState();
    const { id: tid } = getOrganization();
    const { setUserPreferences } = getUser();

    const mutation = useMutation(
        ({ preferences }) => {
            return updateUserPreferences(tid, preferences);
        },
        {
            onSuccess: (data) => {
                setUserPreferences(data.result.preferences);
            },
        },
    );

    return mutation;
}

export function useDeleteAccountMutation() {
    const mutate = useMutation(
        ({ organizationId, userId }) => {
            return deleteAccount(organizationId, userId);
        },
        {
            onError: (error, { userId }) => {
                AppNotification[NotificationTypes.Error]({
                    description: (
                        <Trans i18nKey='DeleteAccount.Error'>
                            We were not able to delete {{ userId: userId }}{' '}
                            account. Please try again later{' '}
                            {{ error: error.toString() }}
                        </Trans>
                    ),
                    duration: 10,
                });
            },
        },
    );

    return mutate;
}

export function useRestoreAccountMutation() {
    const mutate = useMutation(
        ({ organizationId, userId }) => {
            return restoreAccount(organizationId, userId);
        },
        {
            onSuccess: () => {
                AppNotification[NotificationTypes.Success]({
                    description: (
                        <Trans i18nKey='RestoreAccount.Success'>
                            Your Perfect Wiki account was restored successfully.
                            You will be redirected to your user page...
                        </Trans>
                    ),
                    duration: 10,
                });
            },
            onError: (error) => {
                AppNotification[NotificationTypes.Error]({
                    description: (
                        <Trans i18nKey='RestoreAccount.Error'>
                            We were not able to restore your account. Please
                            contact support to get help{' '}
                            {{ error: error.toString() }}.
                        </Trans>
                    ),
                    duration: 5,
                });
            },
        },
    );

    return mutate;
}

export function useLogoutFromAllAccounts() {
    return useCallback((nextUrl) => {
        logoutFromAllAccounts(nextUrl);
    }, []);
}

export function useWrongAccountWarning() {
    const handleLogout = useLogoutFromAllAccounts();

    const showWarning = useCallback(
        (originalUrl) => {
            AppNotification.warning({
                key: 'channel-not-found-warning',
                description: t(
                    'Layout.ChannelNotFound',
                    'Channel or page that you requested is not found. Maybe you logged in with a wrong account?',
                ),
                duration: 0,
                btn: (
                    <Button
                        type='link'
                        size='small'
                        onClick={() => handleLogout(originalUrl)}>
                        {t(
                            'Layout.SignInWinthAnotherAccount',
                            'Sign In With Another Account',
                        )}
                    </Button>
                ),
            });
        },
        [handleLogout],
    );

    return {
        showWarning,
    };
}
