import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { locizePlugin } from 'locize';
import LastUsed from 'locize-lastused';
import { initReactI18next } from 'react-i18next';

import { isLocalStorageAvailable } from './localStorage';

const isProduction = process.env.NODE_ENV === 'production';

const locizeOptions = {
    projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
    apiKey: process.env.REACT_APP_LOCIZE_APIKEY,
    referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
    version: process.env.REACT_APP_LOCIZE_VERSION,
    allowedAddOrUpdateHosts: isProduction
        ? []
        : ['dev.perfectwiki.xyz', 'dev1.perfectwiki.xyz', 'localhost'],
};

if (!isProduction) {
    i18n.use(LastUsed);
}

export const getLanguageForCKEditor = () => {
    if (!isLocalStorageAvailable()) {
        return navigator?.language?.slice(0, 2) || 'en';
    }

    const localStorageLanguage = window.localStorage?.getItem('i18nextLng');
    return localStorageLanguage?.slice(0, 2) || 'en';
};

export const initLanguageFromMsTeams = (msTeamsLocale) => {
    if (isLocalStorageAvailable()) {
        if (!window.localStorage.i18nextLng) {
            i18n?.changeLanguage(msTeamsLocale);
        }
    }
};

i18n.use(locizePlugin)
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: !isProduction,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: locizeOptions,
        locizeLastUsed: locizeOptions,
        saveMissing: !isProduction,
        defaultNS: 'general',
    });

export default i18n;
